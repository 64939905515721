#Body_Squares {
    width: 100%;
    height: 100%;


    background-color: #222222;

    position: fixed;
    top: 0;
    left: 0;
    z-index: 0;
}

#Move_block::-webkit-scrollbar {
    display: none;
}

#Move_block {
    overflow: hidden;

    /*position: relative;*/
    /*border: 1px solid red;*/

    /*background-color: #222222;*/
    height: calc(100vh - 100px);
    width: 90vw;
    margin: 60px auto auto;
    border-radius: 10px;
}

.Layer {
    /*border: 1px solid green;*/

    position: absolute;
    height: calc(100vh - 100px);
    width: 90vw;
    color: white;
    text-align: center;
    vertical-align: middle;
    font-size: 18pt;
    font-weight: 100;
}

.Block_type_1 {
    width: 100px;
    height: 100px;
    background-color: #05953C;
    color: white;
    text-align: center;
    vertical-align: middle;
    border-radius: 20%;
    font-size: 18pt;
    position: absolute;
    opacity: 0.7;
    /*backdrop-filter: blur(10px);*/
}

.Block_type_2 {
    width: 75px;
    height: 75px;
    background-color: #05953C;
    color: white;
    text-align: center;
    vertical-align: middle;
    border-radius: 20%;
    font-size: 18pt;
    position: absolute;
    opacity: 0.5;
}

.Block_type_3 {
    width: 50px;
    height: 50px;
    background-color: #05953C;
    color: white;
    text-align: center;
    vertical-align: middle;
    border-radius: 20%;
    font-size: 18pt;
    position: absolute;
    opacity: 0.15;

}

.Block_type_4 {
    width: 25px;
    height: 25px;
    background-color: #05953C;
    color: white;
    text-align: center;
    vertical-align: middle;
    border-radius: 20%;
    font-size: 18pt;
    position: absolute;
    opacity: 0.03;
}

.Logo_Squares {
    position: absolute;
    top: 0; bottom: 0;
    left: 0; right: 0;
    margin: auto;
    max-width: 60%;
    max-height: 60%;
}

@media (max-width: 650px) {
    .Block_type_1 {
        width: 60px;
        height: 60px;
    }

    .Block_type_2 {
        width: 45px;
        height: 45px;
    }

    .Block_type_3 {
        width: 30px;
        height: 30px;

    }

    .Block_type_4 {
        width: 15px;
        height: 15px;
    }
}






