@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,100..800;1,100..800&display=swap');

*{
  font-family: Montserrat, serif;
  margin:0;
  padding: 0;
}

html {
  /*overflow: scroll;*/
  overflow-y: scroll;
  scroll-behavior: smooth;
}

.App {
  /*border: 1px solid red;*/
  background-color: #222222;
}

#Body {
  overflow-x:hidden
}

/*::-webkit-scrollbar {*/
/*  display: none;*/
/*}*/
