/*Основные настройки*/
.Content_block_General__Design {
    display: block;
    position: relative;
    width: 100%;
    height: calc(100% + 20px);
}

.Border_block_TR_General__Design {
    position: absolute;
    border-top: solid 3px white;
    border-right: solid 3px white;
}

.Border_block_RB_General__Design {
    position: absolute;
    border-right: solid 3px white;
    border-bottom: solid 3px white;
}

.Border_block_BL_General__Design {
    position: absolute;
    border-bottom: solid 3px white;
    border-left: solid 3px white;
}

.Border_block_LT_General__Design {
    position: absolute;
    border-left: solid 3px white;
    border-top: solid 3px white;
}

.Border_block_Line_General__Design {
    position: absolute;
    border-bottom: solid 3px white;
}

.Pointer_General__Design {
    position: absolute;
    height: 16px;
    width: 16px;
    border-right: solid 3px white;
    border-bottom: solid 3px white;
    rotate: 45deg;
}

.Pointer_group_1_General__Design {
    right: -8px;
    top: 0;
}

.Pointer_group_2_General__Design {
    right: -8px;
    top: calc(15px * 1);
}

.Pointer_group_3_General__Design {
    right: -8px;
    top: calc(15px * 2);
}

.Pointer_group_4_General__Design {
    right: -8px;
    top: calc(15px * 3);
}

.Pointer_group_5_General__Design {
    right: -8px;
    top: calc(15px * 4);
}

.Button_site_General__Design {
    bottom: -14px;

    position: absolute;
    margin: auto;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.Button_site_General__Design a {
    font-family: JetBrains Mono, serif;
    font-size: 24px;
    font-style: italic;
    letter-spacing: 5px;
    color: white;
    text-decoration: none;
}

.Button_site_General__Design a:hover {
    text-decoration: underline;
    cursor: pointer;
}

.Button_site_General__Design p {
    font-family: "JetBrains Mono", serif;
}

@media (min-width: 650px) and (max-width: 890px) {
    .Button_site_General__Design {
        bottom: calc(-11px - (100vw - 650px) * 3/240);
    }

    .Button_site_General__Design a {
        font-size: calc(18px + (100vw - 650px) * 6/240);
    }

    .Border_block_TR_General__Design {
        border-top: solid calc(2px + (100vw - 650px) * 1/240) white;
        border-right: solid calc(2px + (100vw - 650px) * 1/240) white;
    }

    .Border_block_RB_General__Design {
        border-right: solid calc(2px + (100vw - 650px) * 1/240) white;
        border-bottom: solid calc(2px + (100vw - 650px) * 1/240) white;
    }

    .Border_block_BL_General__Design {
        border-bottom: solid calc(2px + (100vw - 650px) * 1/240) white;
        border-left: solid calc(2px + (100vw - 650px) * 1/240) white;
    }

    .Border_block_LT_General__Design {
        border-left: solid calc(2px + (100vw - 650px) * 1/240) white;
        border-top: solid calc(2px + (100vw - 650px) * 1/240) white;
    }

    .Border_block_Line_General__Design {
        border-bottom: solid calc(2px + (100vw - 650px) * 1/240) white;
    }

    .Pointer_General__Design {
        height: calc(10px + (100vw - 650px) * 6/240);
        width: calc(10px + (100vw - 650px) * 6/240);
        border-right: solid calc(2px + (100vw - 650px) * 1/240) white;
        border-bottom: solid calc(2px + (100vw - 650px) * 1/240) white;
    }

    .Pointer_group_1_General__Design {
        right: calc(-5px - (100vw - 650px) * 3/240);
        top: 0;
    }

    .Pointer_group_2_General__Design {
        right: calc(-5px - (100vw - 650px) * 3/240);
        top: calc((10px + (100vw - 650px) * 5/240) * 1);
    }

    .Pointer_group_3_General__Design {
        right: calc(-5px - (100vw - 650px) * 3/240);
        top: calc((10px + (100vw - 650px) * 5/240) * 2);
    }

    .Pointer_group_4_General__Design {
        right: calc(-5px - (100vw - 650px) * 3/240);
        top: calc((10px + (100vw - 650px) * 5/240) * 3);
    }

    .Pointer_group_5_General__Design {
        right: calc(-5px - (100vw - 650px) * 3/240);
        top: calc((10px + (100vw - 650px) * 5/240) * 4);
    }
}

@media (max-width: 650px) {
    .Button_site_General__Design {
        bottom: -9px;
    }

    .Button_site_General__Design a {
        font-size: 16px;
    }

    .Border_block_TR_General__Design {
        border-top: solid 2px white;
        border-right: solid 2px white;
    }

    .Border_block_RB_General__Design {
        border-right: solid 2px white;
        border-bottom: solid 2px white;
    }

    .Border_block_BL_General__Design {
        border-bottom: solid 2px white;
        border-left: solid 2px white;
    }

    .Border_block_LT_General__Design {
        border-left: solid 2px white;
        border-top: solid 2px white;
    }

    .Border_block_Line_General__Design {
        border-bottom: solid 2px white;
    }

    .Pointer_General__Design {
        height: 10px;
        width: 10px;
        border-right: solid 2px white;
        border-bottom: solid 2px white;
    }

    .Pointer_group_1_General__Design {
        right: -5px;
        top: 0;
    }

    .Pointer_group_2_General__Design {
        right: -5px;
        top: calc(10px * 1);
    }

    .Pointer_group_3_General__Design {
        right: -5px;
        top: calc(10px * 2);
    }

    .Pointer_group_4_General__Design {
        right: -5px;
        top: calc(10px * 3);
    }

    .Pointer_group_5_General__Design {
        right: -5px;
        top: calc(10px * 4);
    }
}



/*---------------тип 1---------------*/
.Border_block_1__Type1 {
    width: 10%;
    height: 30%;
    right: 0;
}

.Border_block_2__Type1 {
    width: 220px;
    height: calc(100% - 30% - 100px);
    right: 0;
    bottom: 0;
}

.Border_block_3__Type1 {
    width: 220px;
    height: 20px;
    left: 0;
    bottom: -20px;
}

.Pointer_top__Type1 {
    rotate: -45deg;
    top: -8px;
    right: calc(10% + 30px);
}

.Pointer_group__Type1 {
    position: absolute;
    top: 30%;
    right: 0;
}

.Pointer_bottom__Type1 {
    rotate: 45deg;
    left: -8px;
    bottom: calc(-20px - 30px);
}

.Button_site__Type1 {
    left: 0; right: 0;
}

@media (min-width: 650px) and (max-width: 890px) {
    .Border_block_2__Type1 {
        width: calc(150px + (100vw - 650px) * 70/240);
        height: calc(100% - 30% - (70px + (100vw - 650px) * 30/240));
    }

    .Border_block_3__Type1 {
        width: calc(150px + (100vw - 650px) * 70/240);
        height: calc(15px + (100vw - 650px) * 5/240);
        bottom: calc(-15px - (100vw - 650px) * 5/240);
    }

    .Pointer_top__Type1 {
        top: calc(-5px - (100vw - 650px) * 3/240);
        right: calc(10% + (20px + (100vw - 650px) * 10/240));
    }

    .Pointer_bottom__Type1 {
        left: calc(-5px - (100vw - 650px) * 3/240);
        bottom: calc((-15px - (100vw - 650px) * 5/240) - (20px + (100vw - 650px) * 10/240));
    }
}

@media (max-width: 650px) {
    .Border_block_2__Type1 {
        width: 50px;
        height: calc(100% - 30% - 70px);
    }

    .Border_block_3__Type1 {
        width: 50px;
        height: 15px;
        bottom: -15px;
    }

    .Pointer_top__Type1 {
        top: -5px;
        right: calc(10% + 20px);
    }

    .Pointer_bottom__Type1 {
        left: -5px;
        bottom: calc(-15px - 20px);
    }
}



/*---------------тип 2---------------*/
.Border_block_1__Type2 {
    width: 10%;
    height: calc(100% - 60px - 16px - 30px);
    left: 0;
    bottom: 0;
}

.Border_block_1_noLink__Type2 {
    width: calc(75% - 16px - 20px);
    height: calc(100% - 60px - 16px - 30px);
    left: 0;
    bottom: 0;
}

.Pointer_top__Type2 {
    rotate: 45deg;
    top: 60px;
    left: -8px;
}

.Pointer_bottom__Type2 {
    rotate: -45deg;
    bottom: -8px;
    left: 11.5%;
}

.Pointer_bottom_noLink__Type2 {
    rotate: -45deg;
    bottom: -8px;
    right: 25%;
}

.Button_site__Type2 {
    left: 20%;
}

@media (min-width: 650px) and (max-width: 890px) {
    .Border_block_1__Type2 {
        height: calc(100% - (40px + (100vw - 650px) * 20/240) - (10px + (100vw - 650px) * 6/240) - (20px + (100vw - 650px) * 10/240));
    }

    .Border_block_1_noLink__Type2 {
        width: calc(75% - (10px + (100vw - 650px) * 6/240) - (10px + (100vw - 650px) * 10/240));
        height: calc(100% - (40px + (100vw - 650px) * 20/240) - (10px + (100vw - 650px) * 6/240) - (20px + (100vw - 650px) * 10/240));
    }

    .Pointer_top__Type2 {
        top: calc(40px + (100vw - 650px) * 20/240);
        left: calc(-5px - (100vw - 650px) * 3/240);
    }

    .Pointer_bottom__Type2 {
        bottom: calc(-5px - (100vw - 650px) * 3/240);
    }

    .Pointer_bottom_noLink__Type2 {
        bottom: calc(-5px - (100vw - 650px) * 3/240);
        right: 25%;
    }
}

@media (max-width: 650px) {
    .Border_block_1__Type2 {
        width: 45px;
        height: calc(100% - 25px - 10px - 20px);
    }

    .Border_block_1_noLink__Type2 {
        width: calc(75% - 10px - 10px);
        height: calc(100% - 25px - 10px - 20px);
    }

    .Pointer_top__Type2 {
        top: 25px;
        left: -5px;
    }

    .Pointer_bottom__Type2 {
        bottom: -5px;
        left: 55px;
    }

    .Pointer_bottom_noLink__Type2 {
        bottom: -5px;
        right: 25%;
    }

    .Button_site__Type2 {
        left: auto;
        right: 40px;
    }
}



/*---------------тип 3---------------*/
.Border_block_1__Type3 {
    width: 7%;
    height: 50%;
    right: 0;
}

.Border_block_2__Type3 {
    width: 5%;
    height: 50%;
    right: 0;
    bottom: 0;
}

.Border_block_3__Type3 {
    width: 30%;
    height: 0;
    right: calc(5% + 100px);
    bottom: 0;
}

.Border_block_3_noLink__Type3 {
    width: calc(100% - 5% - 100px - 16px - 20px);
    height: 0;
    right: calc(5% + 100px);
    bottom: 0;
}

.Pointer_top__Type3 {
    rotate: -45deg;
    top: -8px;
    right: calc(7% + 30px);
}

.Pointer_group__Type3 {
    position: absolute;
    bottom: 0;
    right: 5%;
    rotate: 90deg;
}

.Pointer_bottom__Type3 {
    rotate: 135deg;
    bottom: -8px;
    right: calc(30% + 5% + 100px + 10px);
}

.Pointer_bottom_noLink__Type3 {
    rotate: 135deg;
    bottom: -8px;
    left: 0;
}

.Button_site__Type3 {
    left: 0;
}

@media (min-width: 650px) and (max-width: 890px) {
    .Border_block_3__Type3 {
        right: calc(5% + (70px + (100vw - 650px) * 30/240));
    }

    .Border_block_3_noLink__Type3 {
        width: calc(100% - 5% - (70px + (100vw - 650px) * 30/240) - (10px + (100vw - 650px) * 6/240) - (10px + (100vw - 650px) * 10/240));
        right: calc(5% + (70px + (100vw - 650px) * 30/240));
    }

    .Pointer_top__Type3 {
        top: calc(-5px - (100vw - 650px) * 3/240);
        right: calc(7% + (20px + (100vw - 650px) * 10/240));
    }

    .Pointer_bottom__Type3 {
        bottom: calc(-5px - (100vw - 650px) * 3/240);
        right: calc(30% + 5% + (70px + (100vw - 650px) * 30/240) + (5px + (100vw - 650px) * 10/240));
    }

    .Pointer_bottom_noLink__Type3 {
        bottom: calc(-5px - (100vw - 650px) * 3/240);
    }
}

@media (max-width: 650px) {
    .Border_block_3__Type3 {
        width: 20px;
        right: calc(5% + 60px);
    }

    .Border_block_3_noLink__Type3 {
        width: calc(100% - 5% - 60px - 10px - 10px);
        right: calc(5% + 60px);
    }

    .Pointer_top__Type3 {
        top: -5px;
        right: calc(7% + 20px);
    }

    .Pointer_bottom__Type3 {
        bottom: -5px;
        right: calc(5% + 85px);
    }

    .Pointer_bottom_noLink__Type3 {
        bottom: -5px;
    }
}



/*---------------тип 4---------------*/
.Border_block_1__Type4 {
    width: 30%;
    height: 83px;
    right: 0;
    top: calc(-330px - 3px);
}

.Border_block_2__Type4 {
    width: calc(100% - 3px);
    height: 50px;
    left: 0;
    top: -250px;
}

.Border_block_3__Type4 {
    width: 4%;
    height: 100px;
    left: 0;
    top: -100px;
}

.Border_block_4__Type4 {
    width: 4%;
    height: 30%;
    right: 0;
}

.Border_block_5__Type4 {
    width: 0;
    height: calc(100% - 30% - 100px);
    right: 0;
    bottom: 0;
}

.Pointer_1__Type4 {
    rotate: 45deg;
    top: -150px;
    left: -8px;
}

.Pointer_2__Type4 {
    rotate: 45deg;
    top: -180px;
    left: -8px;
}

.Pointer_3__Type4 {
    rotate: -45deg;
    top: -8px;
    left: calc(4% + 20px);
}

.Pointer_4__Type4 {
    rotate: -45deg;
    top: -8px;
    right: calc(4% + 30px);
}

.Pointer_group__Type4 {
    position: absolute;
    top: 30%;
    right: 0;
}

@media (min-width: 650px) and (max-width: 890px) {
    .Border_block_1__Type4 {
        height: calc(62px + (100vw - 650px) * 21/240);
        top: calc(-262px - (100vw - 650px) * 71/240);
    }

    .Border_block_2__Type4 {
        width: calc(100% - (2px + (100vw - 650px) * 1/240));
        height: calc(40px + (100vw - 650px) * 10/240);
        top: calc(-200px - (100vw - 650px) * 50/240);
    }

    .Border_block_3__Type4 {
        height: calc(90px + (100vw - 650px) * 10/240);
        top: calc(-90px - (100vw - 650px) * 10/240);
    }

    .Border_block_4__Type4 {
    }

    .Border_block_5__Type4 {
        height: calc(100% - 30% - (70px + (100vw - 650px) * 30/240));
    }

    .Pointer_1__Type4 {
        top: calc(-150px - (100vw - 650px) * 30/240);
        left: calc(-5px - (100vw - 650px) * 3/240);
    }

    .Pointer_2__Type4 {
        top: calc(-124px - (100vw - 650px) * 25/240);
        left: calc(-5px - (100vw - 650px) * 3/240);
    }

    .Pointer_3__Type4 {
        top: calc(-5px - (100vw - 650px) * 3/240);
        left: calc(4% + (10px + (100vw - 650px) * 10/240));
    }

    .Pointer_4__Type4 {
        top: calc(-5px - (100vw - 650px) * 3/240);
        right: calc(4% + (20px + (100vw - 650px) * 10/240));
    }

    .Pointer_group__Type4 {
        top: 30%;
    }
}

@media (max-width: 650px) {
    .Border_block_1__Type4 {
        width: 20px;
        height:42px;
        top: -232px;
    }

    .Border_block_2__Type4 {
        height: 50px;
        top: -190px;
    }

    .Border_block_3__Type4 {
        width: 60px;
        height: 70px;
        top: -70px;
    }

    .Border_block_4__Type4 {
        width: 60px;
    }

    .Border_block_5__Type4 {
        height: calc(100% - 30% - 70px);
    }

    .Pointer_1__Type4 {
        top: -105px;
        left: -5px;
    }

    .Pointer_2__Type4 {
        top: -130px;
        left: -5px;
    }

    .Pointer_3__Type4 {
        top: -5px;
        left: 70px;
    }

    .Pointer_4__Type4 {
        top: -5px;
        right: 80px;
    }

    .Pointer_group__Type4 {
    }
}





/*---------------тип 5---------------*/
.Border_block_1__Type5 {
    width: 27%;
    height: 200px;
    right: 0;
    top: -200px;
}

.Border_block_2__Type5 {
    width: 32%;
    height: 30%;
    left: 0;
    top: 0;
}

.Border_block_3__Type5 {
    width: 0;
    height: calc(100% - 30% - 100px);
    left: 0;
    bottom: 0;
}

.Pointer_1__Type5 {
    rotate: 135deg;
    top: -8px;
    right: calc(27% + 20px);
}

.Pointer_2__Type5 {
    rotate: 135deg;
    top: -8px;
    left: calc(32% + 30px);
}

.Pointer_group__Type5 {
    position: absolute;
    top: 30%;
    left: 3px;
}

@media (min-width: 650px) and (max-width: 890px) {
    .Border_block_1__Type5 {
        height: calc(150px + (100vw - 650px) * 50/240);
        top: calc(-150px - (100vw - 650px) * 50/240);
    }

    .Border_block_2__Type5 {
    }

    .Border_block_3__Type5 {
        height: calc(100% - 30% - calc(70px + (100vw - 650px) * 30/240));
    }

    .Pointer_1__Type5 {
        top: calc(-5px - (100vw - 650px) * 3/240);
        right: calc(27% + calc(10px + (100vw - 650px) * 10/240));
    }

    .Pointer_2__Type5 {
        top: calc(-5px - (100vw - 650px) * 3/240);
        left: calc(32% + calc(20px + (100vw - 650px) * 10/240));
    }

    .Pointer_group__Type5 {
        left: calc(2px - (100vw - 650px) * 1/240);
    }
}

@media (max-width: 650px) {
    .Border_block_1__Type5 {
        width: 50px;
        height: 150px;
        top: -150px;
    }

    .Border_block_2__Type5 {
        width: 70px;
    }

    .Border_block_3__Type5 {
        height: calc(100% - 30% - 70px);
    }

    .Pointer_1__Type5 {
        top: -5px;
        right: 60px;
    }

    .Pointer_2__Type5 {
        top: -5px;
        left: 90px;
    }

    .Pointer_group__Type5 {
        left: 2px;
    }
}





/*---------------тип 6---------------*/
.Border_block_1__Type6 {
    height: 200px;
    width: 7.5%;
    left: 0;
    top: -200px;
}
.Border_block_2__Type6 {
    width: 32%;
    height: 0;
    right: calc(7.5% + 30px + 20px + 16px);
    top: 0;
}

.Border_block_3__Type6 {
    width: 7.5%;
    height: 50%;
    right: 0;
    top: 0;
}

.Border_block_4__Type6 {
    width: calc(100% - 16px - 30px);
    height: 50%;
    right: 0;
    bottom: 0;
}

.Pointer_1__Type6 {
    rotate: -45deg;
    top: -8px;
    left: calc(7.5% + 20px);
}

.Pointer_2__Type6 {
    rotate: -45deg;
    top: -8px;
    right: calc(7.5% + 30px + 20px + 16px + 32% + 30px);
}

.Pointer_3__Type6 {
    rotate: -45deg;
    top: -8px;
    right: calc(7.5% + 30px);
}

.Pointer_4__Type6 {
    rotate: -45deg;
    bottom: -8px;
    left: 0;
}

@media (min-width: 650px) and (max-width: 890px) {
    .Border_block_1__Type6 {
        height: calc(150px + (100vw - 650px) * 50/240);
        top: calc(-150px - (100vw - 650px) * 50/240);
    }
    .Border_block_2__Type6 {
        right: calc(7.5% +
        (20px + (100vw - 650px) * 10/240) +
        (10px + (100vw - 650px) * 10/240) +
        (10px + (100vw - 650px) * 6/240));
    }

    .Border_block_3__Type6 {
    }

    .Border_block_4__Type6 {
        width: calc(100% - calc(10px + (100vw - 650px) * 6/240) - calc(20px - (100vw - 650px) * 10/240));
    }

    .Pointer_1__Type6 {
        top: calc(-5px - (100vw - 650px) * 3/240);
        left: calc(7.5% + (10px + (100vw - 650px) * 10/240));
    }

    .Pointer_2__Type6 {
        top: calc(-5px - (100vw - 650px) * 3/240);
        right: calc(7.5% + 32% +
        (20px + (100vw - 650px) * 10/240) +
        (10px + (100vw - 650px) * 10/240) +
        (10px + (100vw - 650px) * 6/240) +
        (20px + (100vw - 650px) * 10/240));
    }

    .Pointer_3__Type6 {
        top: calc(-5px - (100vw - 650px) * 3/240);
        right: calc(7.5% + (20px + (100vw - 650px) * 10/240));
    }

    .Pointer_4__Type6 {
        bottom: calc(-5px - (100vw - 650px) * 3/240);
    }
}

@media (max-width: 650px) {
    .Border_block_1__Type6 {
        height: 150px;
        width: 15px;
        top: -150px;
    }
    .Border_block_2__Type6 {
        width: 80px;
        right: 70px;
    }

    .Border_block_3__Type6 {
        width: 30px;
    }

    .Border_block_4__Type6 {
        width: calc(100% - 10px - 20px);
    }

    .Pointer_1__Type6 {
        top: -5px;
        left: 25px;
    }

    .Pointer_2__Type6 {
        top: -5px;
        right: 170px;
    }

    .Pointer_3__Type6 {
        top: -5px;
        right: 50px;
    }

    .Pointer_4__Type6 {
        bottom: -5px;
    }
}