#Main_block__EndBlock {
    position: static;
    margin: 0 auto 0;
    display: block;
    width: 800px;
}

#Content_block_1__EndBlock {
    display: block;
    position: relative;
    height: 50px;
    width: 800px;
    margin-top: 70px;
}

#String__EndBlock {
    position: absolute;
    display: block;
    text-align: center;
    top: auto; bottom: 10px;
    left: 0; right: 0;
}

#String__EndBlock p {
    font-family: "JetBrains Mono", serif;
    font-size: 14px;
    color: #686868;
}

@media (min-width: 650px) and (max-width: 890px) {
    #Main_block__EndBlock {
        width: 95vw;
    }

    #Content_block_1__EndBlock {
        width: 100%;
        margin-top: calc(50px + (100vw - 650px) * 20/240);
    }
}

@media (max-width: 650px) {
    #Main_block__EndBlock {
        width: 95vw;
    }

    #Content_block_1__EndBlock {
        height: 50px;
        width: 100%;
    }

    #String__EndBlock {
        position: absolute;
        display: block;
        text-align: center;
        top: auto; bottom: 10px;
        left: 0; right: 0;
    }

    #String__EndBlock p {
        font-family: "JetBrains Mono", serif;
        font-size: 10px;
        color: #686868;
    }

}