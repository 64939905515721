.Main_block {
    position: relative;
    margin: 0 auto 200px;
    width: 800px;
}

:last-child.Main_block {
    margin-bottom: 350px;
}

.Content_block {
    position: relative;
    z-index: 1;
    width: calc(100% - 40px);
    padding-bottom: 40px;
    padding-top:20px;
    margin-left: 40px;
}

.Design {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
}

.Heading {
    position: absolute;
    font-family: JetBrains Mono, serif;
    color: #039C00;
    font-weight: bold;
    font-size: 56px;
    top: -40px;
    left: -3px;
}

.Heading_small {
    position: absolute;
    font-family: JetBrains Mono, serif;
    color: #039C00;
    font-weight: bold;
    font-size: 40px;
    top: -27px;
    margin-right: auto;
    margin-left: auto;
    left: 0; right: 0;
    text-align: center;
}

.Subtitle {
    width: 100%;
    letter-spacing: 1px;
    font-weight: 600;
    color: white;
    font-size: 28px;
    line-height: 150%;
    margin-top: 30px;
}

.Text_description {
    letter-spacing: 1px;
    font-weight: 500;
    color: white;
    font-size: 24px;
    line-height: 150%;
    margin-top: 30px;
}

.Text_body {
    letter-spacing: 1px;
    font-weight: 400;
    color: white;
    font-size: 20px;
    line-height: 150%;
    margin-top: 30px;
}

.Text_Help {
    font-family: "JetBrains Mono", serif;
    text-align: center;
    color: #039C00;
    font-weight: bold;
    font-size: 20px;
    margin-top: 7px;
}

.List {
    list-style-type: '•  ';
    margin-left: 30px;
}

.Image {
    width: 37.5%;
    float: right;
    margin: 40px 0 40px 40px;
}

.Product_Logo {
    width: 20%;
    float: right;
    margin: 40px 0 40px 40px;
    border-radius: 25%;
}

.Product_Logo_Left {
    width: 20%;
    float: left;
    margin: 40px 40px 40px 0;
    border-radius: 25%;
}

.Buy {
    display: block;
    font-weight: bold;
    margin-top: 30px;
}

.Buy H1 {
    font-family: "JetBrains Mono", serif;
    color: #039C00;
    font-size: 32px;
}

.Buy H2 {
    font-family: "JetBrains Mono", serif;
    color: #686868;
    font-size: 20px;
    margin-top: 7px;
}

.Download {
    display: block;
    width: 90%;
}

.Download_flex {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 30px;
}

.Download_button_1 {
    display: flex;
    width: 100%;
    height: 50px;
    border: solid 1.5px white;
    background-color: transparent;
    border-radius: 10px;
    transition: 0.3s;
}

.Download_button_1 p{
    font-family: "JetBrains Mono", serif;
    font-weight: bold;
    color: #039C00;
    font-size: 24px;
    margin: auto;
}

.Download_button_1:hover {
    cursor: pointer;
}

:not(:last-child).Download_button_1 {
    margin-right: 15px;
}

.Download_OS {
    margin: auto;
    max-width: 70%;
    max-height: 60%;

}

.Download_button_2 {
    width: 100%;
    display: flex;
    height: 40px;
    margin-top: 15px;
    border: solid 1.5px #686868;
    background-color: transparent;
    border-radius: 10px;
    transition: 0.3s;
    text-decoration: none;
}

.Download_button_2 p {
    font-family: "JetBrains Mono", serif;
    font-weight: bold;
    color: #686868;
    font-size: 16px;
    margin: auto;
}

.Download_button_2:hover {
    cursor: pointer;
}

.Download_button_3 {
    display: flex;
    width: 100%;
    height: 80px;
    border: solid 2px white;
    border-radius: 20px;
    transition: 0.3s;
    margin-top: 30px;
    color: white;

    outline: none;
    background-color: transparent;
    cursor: pointer;
    position: relative;
}

.Download_button_3:hover {
    background-color: #222422;
    border-color: #7DFB94;
    color: #7DFB94
}

.Download_button_3:active {
    background-color: #222422;
    border-color: #7DFB94;
    color: #7DFB94
}

.Download_button_3 p {
    font-family: "JetBrains Mono", serif;
    font-weight: bold;
    font-size: 26px;
    letter-spacing: 2px;
    margin: auto;
}

.Download_button_3:before {
    content: '';
    background: linear-gradient(45deg, #25AF3E, #2198DF, #25AF3E);
    position: absolute;
    top: -2px;
    left:-2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(10px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;
}

.Download_button_3:hover:before {
    opacity: 1;
}

.Download_button_3:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #222222;
    border-radius: 20px;
}

@keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
}

.Download_Logo {
    width: 100px;
    border-radius: 25%;
    margin-right: 30px;
}

@media (min-width: 650px) and (max-width: 890px) {
    .Main_block {
        margin: 0 auto calc(150px + (100vw - 650px) * 50/240);
        width: 90vw;
    }

    :last-child.Main_block {
        margin-bottom: calc(280px + (100vw - 650px) * 70/240);
    }

    .Content_block {
        width: calc(100% - (30px + (100vw - 650px) * 10/240));
        padding-bottom: calc(30px + (100vw - 650px) * 10/240);
        padding-top: calc(10px + (100vw - 650px) * 10/240);
        margin-left: calc(30px + (100vw - 650px) * 10/240);
    }

    .Heading {
        font-size: calc(40px + (100vw - 650px) * 16/240);
        top: calc(-30px - (100vw - 650px) * 10/240);
    }

    .Heading_small {
        font-size: calc(28px + (100vw - 650px) * 12/240);
        top: calc(-20px - (100vw - 650px) * 7/240);
    }

    .Subtitle {
        font-size: calc(20px + (100vw - 650px) * 8/240);
        margin-top: calc(20px + (100vw - 650px) * 10/240);
    }

    .Text_description {
        font-size: calc(18px + (100vw - 650px) * 6/240);
        margin-top: calc(20px + (100vw - 650px) * 10/240);
    }

    .Text_body {
        font-size: calc(14px + (100vw - 650px) * 6/240);
        margin-top: calc(20px + (100vw - 650px) * 10/240);
    }

    .Text_Help {
        font-size: calc(14px + (100vw - 650px) * 6/240);
        margin-top: calc(2px + (100vw - 650px) * 5/240);
    }

    .List {
        margin-left: calc(25px + (100vw - 650px) * 5/240);
    }

    .Image {
        margin: calc(30px + (100vw - 650px) * 10/240) 0
        calc(30px + (100vw - 650px) * 10/240)
        calc(30px + (100vw - 650px) * 10/240);
    }

    .Product_Logo {
        margin: calc(30px + (100vw - 650px) * 10/240) 0
        calc(30px + (100vw - 650px) * 10/240)
        calc(30px + (100vw - 650px) * 10/240);
    }

    .Product_Logo_Left {
        margin: calc(30px + (100vw - 650px) * 10/240)
        calc(30px + (100vw - 650px) * 10/240)
        calc(30px + (100vw - 650px) * 10/240) 0;
    }

    .Buy {
        margin-top: calc(20px + (100vw - 650px) * 10/240);
    }

    .Buy H1 {
        font-size: calc(26px + (100vw - 650px) * 6/240)
    }

    .Buy H2 {
        font-size: calc(14px + (100vw - 650px) * 6/240);
        margin-top: calc(2px + (100vw - 650px) * 5/240);
    }

    .Download {
        margin-top: calc(20px + (100vw - 650px) * 10/240);
    }

    .Download_flex {
        margin-bottom: calc(10px + (100vw - 650px) * 5/240);
    }

    :not(:last-child).Download_button_1 {
        margin-right: calc(10px + (100vw - 650px) * 5/240);
    }

    .Download_button_2 {
        margin-top: calc(10px + (100vw - 650px) * 5/240);
    }

    .Download_button_2 p {
        font-size: calc(14px + (100vw - 650px) * 2/240);
    }

    .Download_button_3 {
        height: calc(70px + (100vw - 650px) * 10/240);
        margin-top: calc(20px + (100vw - 650px) * 10/240);
    }

    .Download_button_3 p {
        font-size: calc(20px + (100vw - 650px) * 6/240);
    }

    .Download_Logo {
        width: calc(75px + (100vw - 650px) * 25/240);
        margin-right: calc(20px + (100vw - 650px) * 10/240);
    }
}

@media (max-width: 650px) {
    .Main_block {
        margin: 0 auto 150px;
        width: 350px;
    }

    :last-child.Main_block {
        margin-bottom: 250px;
    }

    .Content_block {
        width: calc(100% - 25px);
        padding-bottom: 30px;
        padding-top: 5px;
        margin-left: 25px;
    }

    .Heading {
        font-size: 28px;
        top: -20px;
    }

    .Heading_small {
        font-size: 22px;
        top: -15px;
    }

    .Subtitle {
        font-size: 18px;
        margin-top: 15px;
    }

    .Text_description {
        font-size: 16px;
        margin-top: 15px;
    }

    .Text_body {
        font-size: 12px;
        margin-top: 15px;
    }

    .Text_Help {
        font-size: 12px;
        margin-top: 2px;
    }

    .List {
        margin-left: 20px;
    }

    .Image {
        width: 40%;
        margin: 25px 0 20px 20px;
    }

    .Product_Logo {
        width: 30%;
        margin: 20px 0 20px 20px;
    }

    .Product_Logo_Left {
        width: 30%;
        margin: 20px 20px 20px 0;
    }

    .Buy {
        margin-top: 15px;
    }

    .Buy H1 {
        font-size: 22px;
    }

    .Buy H2 {
        font-size: 12px;
        margin-top: 2px;
    }

    .Download {
        margin-top: 15px;
    }

    .Download_flex {
        margin-bottom: 10px;
    }

    :not(:first-child).Download_flex {
        flex-direction: column;
    }

    .Download_flex_vertical {
        flex-direction: column;
    }

    :not(:last-child).Download_button_1 {
        margin-right: 0;
        margin-bottom: 10px;
    }

    .Download_button_2 {
        margin-top: 10px;
    }

    .Download_button_2 p {
        font-size: 12px;
    }

    .Download_button_3 {
        height: 50px;
        margin-top: 10px;
    }

    .Download_button_3 p {
        font-size: 14px;
    }

    .Download_Logo {
        width: 60px;
        margin-right: 15px;
    }
}






.Infographics {
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    margin-top: 30px;
}

.Infographics_block {
    width: 30%;
    display: block;
    text-align: center;
}

.Infographics_block h1 {
    font-family: JetBrains Mono, serif;
    color: #039C00;
    font-weight: 800;
    font-size: 32px;
}

.Infographics_block h2 {
    font-family: JetBrains Mono, serif;
    color: white;
    font-weight: 500;
    font-size: 24px;
}

.Pointer_infographics_1 {
    height: 16px;
    width: 16px;
    border-right: solid 3px white;
    border-bottom: solid 3px white;
    position: relative;
    margin: auto;
    top: 0; bottom: 0;
    left: -5px; right: auto;
    rotate: -45deg;
}

.Pointer_infographics_2 {
    height: 16px;
    width: 16px;
    border-right: solid 3px white;
    border-bottom: solid 3px white;
    position: relative;
    margin: auto;
    top: 0; bottom: 0;
    left: auto; right: -5px;
    rotate: -45deg;
}

.Partners {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
    margin-top: 50px;
}

.Partners_block {
    position: relative;
    width: 30%;
    height: 80px;
    /*background-color: white;*/
    border: solid 1px white;
    border-radius: 10px;
    margin-bottom: 50px;
}

.Partners_block img {
    position: absolute;
    top: 0; bottom: 0;
    left: 0; right: 0;
    margin: auto;
    max-width: 80%;
    max-height: 70%;
}

.Contacts {
    display: block;
    text-align: left;
    margin-top: 60px;
}

.Contacts a {
    font-family: JetBrains Mono, serif;
    color: #039C00;
    font-weight: bold;
    font-size: 30px;
    text-decoration: none;
    transition: 0.2s;
}

.Contacts a:hover {
    color: #10B50D;
}

.Contacts p {
    font-family: JetBrains Mono, serif;
    color: #686868;
    font-weight: bold;
    font-size: 22px;
}

.Map {
    display: block;
    overflow: hidden;
    width: 100%;
    height: 300px;

    border: 0;
    border-radius: 10px;
    allowFullScreen: true;

    margin-top: 60px;
}

@media (min-width: 650px) and (max-width: 890px) {
    .Infographics {
        margin-top: calc(20px + (100vw - 650px) * 10/240);
    }

    .Infographics_block h1 {
        font-size: calc(22px + (100vw - 650px) * 10/240);
    }

    .Infographics_block h2 {
        font-size: calc(18px + (100vw - 650px) * 6/240);
    }

    .Pointer_infographics_1 {
        height: calc(10px + (100vw - 650px) * 6/240);
        width: calc(10px + (100vw - 650px) * 6/240);
        border-right: solid calc(2px + (100vw - 650px) * 1/240) white;
        border-bottom: solid calc(2px + (100vw - 650px) * 1/240) white;
        left: calc(2px + (100vw - 650px) * 3/240);
    }

    .Pointer_infographics_2 {
        height: calc(10px + (100vw - 650px) * 6/240);
        width: calc(10px + (100vw - 650px) * 6/240);
        border-right: solid calc(2px + (100vw - 650px) * 1/240) white;
        border-bottom: solid calc(2px + (100vw - 650px) * 1/240) white;
        right: calc(-2px - (100vw - 650px) * 3/240);
    }

    .Partners {
        margin-top: calc(30px + (100vw - 650px) * 20/240);
    }

    .Partners_block {
        height: calc(70px + (100vw - 650px) * 10/240);
        margin-bottom: calc(30px + (100vw - 650px) * 20/240);
    }

    .Contacts {
        margin-top: calc(40px + (100vw - 650px) * 20/240);
    }

    .Contacts a {
        font-size: calc(24px + (100vw - 650px) * 6/240);
    }

    .Contacts p {
        font-size: calc(18px + (100vw - 650px) * 4/240);
    }

    .Map {
        height: calc(250px + (100vw - 650px) * 50/240);
        margin-top: calc(40px + (100vw - 650px) * 20/240);
    }
}

@media (max-width: 650px) {
    .Infographics {
        flex-direction: column;
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .Infographics_block {
        width: 100%;
    }

    .Infographics_block h1 {
        font-size: 22px;
    }

    .Infographics_block h2 {
        font-size: 14px;
    }

    .Pointer_infographics_1 {
        height: 10px;
        width: 10px;
        border-right: solid 2px white;
        border-bottom: solid 2px white;
        position: static;
        margin-top: 20px;
        margin-bottom: 20px;
        rotate: 45deg;
    }

    .Pointer_infographics_2 {
        height: 10px;
        width: 10px;
        border-right: solid 2px white;
        border-bottom: solid 2px white;
        position: static;
        margin-top: 20px;
        margin-bottom: 20px;
        rotate: 45deg;
    }

    .Partners {
        margin-top: 30px;
    }

    .Partners_block {
        width: 45%;
        height: 60px;
        margin-bottom: 10px;
    }

    .Partners_block img {
    }

    .Contacts {
        margin-top: 30px;
    }

    .Contacts a {
        font-size: 18px;
    }

    .Contacts p {
        font-size: 12px;
    }

    .Map {
        height: 200px;
        margin-top: 30px;
    }
}





.content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease; /* Добавьте переход для плавности */
}

.content.show {
    max-height: 500px; /* Установите максимальную высоту для плавного раскрытия */
}