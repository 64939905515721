#Gradient_Block {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin-bottom: 30px;
    text-align: center;
}

.Link__StartBlock {
    position: absolute;
    top: auto; bottom: 100px;
    margin: auto;
}

#Gradient {
    width: 1050px;
    display: block;
    margin: calc((100vh - 1050px) / 2 + 30px) auto auto;
    opacity: 0.7;
}

#Gradient_text {
    position: absolute;
    top: calc(50vh - 80px); bottom: auto;
    left: 0; right: 0;
    margin: auto;
}

#Gradient_text h1 {
    /*font-family: "JetBrains Mono", serif;*/
    color: #8CF0A2;
    font-size: 80px;
    font-weight: 700;
}

#Gradient_text p {
    font-family: "JetBrains Mono", serif;
    color: #75EE6B;
    margin-top: 0.1em;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0;
}


@media (min-width: 650px) and (max-width: 890px) {
    #Gradient {
        width: calc(800px + (100vw - 650px) * 250/240);
        margin-top: calc((100vh - (800px + (100vw - 650px) * 250/240)) / 2 + 30px);
    }

    #Gradient_text {
        top: calc(50vh - calc(60px + (100vw - 650px) * 20/240)); bottom: auto;
    }

    #Gradient_text h1 {
        font-size: calc(60px + (100vw - 650px) * 20/240);
    }

    #Gradient_text p {
        font-size: calc(16px + (100vw - 650px) * 4/240);
    }
}


@media (max-width: 650px) {
    #Gradient {
        width: 200%;
        margin: 0;
    }

    #Gradient_text {
        top: 40%;
        bottom: auto;
    }

    #Gradient_text h1 {
        font-size: 42px;
    }

    #Gradient_text p {
        font-size: 14px;
    }
}