.toggle-button {
    border: solid 1px red;
    width: 100px;
    padding: 1rem;
    background-color: transparent;
    color: white;
    /*border: none;*/
    /*width: 100%;*/
    display: none; /* Кнопка скрыта по умолчанию */
}

.items-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: none; /* Список скрыт по умолчанию */
    flex-direction: column;
    justify-content: center;
}

.items-list.show {
    display: flex; /* Показать список */
}

@media (min-width: 651px) {
    .full-width-block {
        height: auto; /* Высота блока адаптируется под содержимое */
    }

    .items-list {
        flex-direction: row; /* Элементы в строчку */
    }

    .toggle-button.hide {
        display: none; /* Скрыть кнопку на больших экранах */
    }
}

@media (max-width: 650px) {
    .toggle-button {
        display: block; /* Показать кнопку на маленьких экранах */
    }

    .full-width-block.expanded .items-list {
        display: flex; /* Показать список при активации */
        flex-direction: column; /* Элементы вертикально */
    }
}

#Header_body {
    display: block;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 2;
}

#Header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /*height: 60px;*/
    width: 100%;

    background: rgba(25, 25, 25, 0.5);
    backdrop-filter: blur(20px) saturate(130%) brightness(100%);
}
.full-width-block {
    top: 0;
    left: 0;
    width: 100%;
    background: rgba(25, 25, 25, 0.5);
    backdrop-filter: blur(20px) saturate(130%) brightness(100%);
    z-index: 3;
    transition: height 0.3s ease;
    overflow: hidden;
    height: 60px; /* Начальная высота блока */
}

.full-width-block.expanded {
    height: 270px; /* Высота блока при раскрытии списка */
}

.full-width-block.collapsed {
    height: 60px; /* Высота блока при скрытии списка */
}

#Media_block {
    width: 100%;
    position: fixed;
    top: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 60px;
}

#Logo_s {
    height: 70%;
    margin-left: 20px;
}

#Name_US {
    /*color: #039C00;*/
    color: white;
    font-size: 26px;
    font-weight: 600;
    margin-left: 20px;
    margin-right: auto;
}

@media (min-width: 650px) and (max-width: 890px) {
    #Name_US {
        font-size: calc(20px + (100vw - 650px) * 6/240);
    }
}

@media (max-width: 650px) {
    #Name_US {
        font-size: 20px;
    }
}

#Logo_US_unofficial {
    height: 50%;
    margin-left: 30px;
}

#Link_block {
    position: fixed;
    right: 0;
    font-family: Montserrat, serif;
    display: flex;
    flex-direction: row;
    text-align: right;
    margin-right: 50px;
}

.Link {
    text-decoration: none;
    font-weight: 400;
    margin: 1vw;
    color: white;
    transition: 0.2s;
    font-size: 13px;
}

.Link:hover {
    color: #05953C;
}

#Header_border {
    height: 1px;
    width: 100%;
    background: rgba(200, 200, 200, 0.15);
}


@media (max-width: 650px) {
    .Menu_Link {
        display: flex;
        flex-direction: column;
    }

    #Menu_button {
        position: fixed;
        right: 20px;
        background-color: transparent;
        /*margin: 30px;*/

        border: none;
        cursor: pointer;
        font-size: 16px;
        margin-right: 10px;

        font-weight: 400;
        color: white;
        transition: 0.2s;
    }

    #Menu_button:hover {
        color: #05953C;
    }


    #Link_block {
        width: 200px;

        position: fixed;
        top: 60px;

        display: flex;
        flex-direction: column;
        margin-right: 10px;
    }

    .Link {
        margin: 10px 45px 10px 10px;
        text-decoration: none;
        font-weight: 400;
        color: white;
        transition: 0.2s;
        font-size: 16px;
    }

    .Link:hover {
        color: #05953C;
    }
}


.LanguageSwitcher {
    top: 15px;
    right: 10px;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: end;
    z-index: 1;
    /*width: 100px;*/
    font-size: 13px;
}

.ButtonSwitcher {
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(20px) saturate(130%) brightness(100%);
    color: white;
    height: 30px;
    width: 30px;
    cursor: pointer;
    margin-bottom: 10px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: solid 1px transparent;
    transition: 0.2s;
}

.ButtonSwitcher:hover {
    /*border: solid 1px red;*/
    background: rgba(0, 0, 0, 0.5);
}

.ButtonSwitcher img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 70%;
}

.MenuSwitcher {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100%;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(20px) saturate(130%) brightness(100%);
    border: none;
    width: 100px;
    padding: 10px;
    height: 60px;
    border-radius: 12px;
}

.ButtonLanguage {
    border: none;
    width: 90%;
    height: 24px;
    margin: auto;
    cursor: pointer;
    border-radius: 6px;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(20px) saturate(130%) brightness(100%);
    color: white;
}

.ButtonLanguage:hover {
    background: rgba(0, 0, 0, 0.5);
    color: #05953C;
    /*border: solid 1px ;*/
}

@media (max-width: 650px) {
    .MenuSwitcher {
        width: 120px;
        height: 80px;
        border-radius: 12px;
    }

    .ButtonLanguage {
        height: 30px;
        font-size: 16px;
    }
}


.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(25px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.modal-content {
    position: relative;
    background-color: #2D2D2D;
    border-radius: 15px;
    max-width: 650px;
    width: 90%;
    height: 400px;
    color: white;
}

.FlexBox {
    position: absolute;
    width: calc(100% - 80px);
    height: calc(100% - 80px);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    /*border: solid 1px red;*/
}

.FlexDynamic {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.Border_block_1 {
    position: absolute;
    border-left: solid 2px white;
    border-top: solid 2px white;
    height: 50%;
    width: 10%;
    top: 0; left: 0;
}

.Border_block_2 {
    position: absolute;
    border-left: solid 2px white;
    border-bottom: solid 2px white;
    height: 50%;
    width: 30%;
    bottom: 0; left: 0;
}

.Pointer_group_modal {
    position: absolute;
    bottom: 2px;
    left: calc(30% + 30px);
    rotate: -90deg;
}

.Pointer_modal {
    position: absolute;
    height: 10px;
    width: 10px;
    border-right: solid 2px white;
    border-bottom: solid 2px white;
    top: -5px;
    rotate: 45deg;
}

.Pointer_1 {
    rotate: 135deg;
    left: calc(10% + 20px);
}
.Pointer_2 {
    rotate: 135deg;
    left: calc(10% + 45px);
}

.Pointer_group_1_modal {
    right: -5px;
    top: 0;
}

.Pointer_group_2_modal {
    right: -5px;
    top: calc(15px * 1);
}

.Pointer_group_3_modal {
    right: -5px;
    top: calc(15px * 2);
}

.Pointer_group_4_modal {
    right: -5px;
    top: calc(15px * 3);
}

.Pointer_group_5_modal {
    right: -5px;
    top: calc(15px * 4);
}

.Title {
    color: #52A634;
    font-size: 24px;
    font-family: "JetBrains Mono", serif;
    font-weight: 400;
    position: absolute;
    top: -15px;
    right: 50px;
    font-style: oblique;
    text-align: right;
}

.Label {
    font-size: 14px;
    font-family: "JetBrains Mono", serif;
    margin-left: 40px;
    white-space: nowrap;
}

.Block_input {
    background-color: #494949;
    border: solid 1px #A5A5A5;
    border-radius: 5px;
    height: 32px;
    font-family: "JetBrains Mono", serif;
    margin-left: 10px;
    width: 100%;
}

.Forma {
    width: 100%;
    height: calc(100% - 80px);
    position: absolute;
    top: 0; bottom: 0;
    left: 0; right: 0;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.ButtonEnter {
    width: 30%;
    height: 32px;
    border: solid 1px #31A81E;
    background-color: #2D382B;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    font-family: "JetBrains Mono", serif;

    position: absolute;
    right: 0;
    bottom: -56px;
}

.ButtonClose {
    position: absolute;
    top: -10px; right: 0;
    border: none;

    height: 24px;
    width: 24px;
    border-radius: 50%;
    background-color: #494949;
}

@media (min-width: 600px) and (max-width: 890px) {
    .Title {
        font-size: calc(20px + (100vw - 650px) * 4/290);
        top: calc(-13px - (100vw - 650px) * 2/290);
    }
}


@media (max-width: 600px) {
    .Title {
        font-size: 20px;
        top: -22px;
        width: 200px;
        right: 50px;
    }

    .modal-content {
        height: 550px;
    }

    .FlexDynamic {
        margin-top: 15px;
        flex-direction: column;
    }

    .Label {
        margin-bottom: 5px;
        margin-left: 10%;
    }

    .Block_input {
        margin-left: 10%;
        margin-bottom: 15px;
        width: 90%;
    }
}